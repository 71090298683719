import React from "react";
import { Product } from "../types/types";
import * as XLSX from 'xlsx';

const BomTable = ({ bom }: { bom: Product["bom"] }) => {
  
  const handleDownload = () => {
    const data = bom.map((item: any) => ({
      Component: item.name,
      Qty: item.quantity,
      Price: item.price, 
      Specifications: item.description
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BOM");
    XLSX.writeFile(workbook, "bom.xlsx");
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <h1 className="mb-2">BOM:</h1>
        </div>
        <div>
          <button onClick={handleDownload}>
            Download as Excel
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr className="text-left border">
            <th className="px-4 py-2">Component</th>
            <th className="px-4 py-2">Quantity</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Specifications</th>
          </tr>
        </thead>
        <tbody className="border">
          {bom.map((item: any) => (
            <tr key={item.name} className="border">
              <td className="px-4 py-1">{item.name}</td>
              <td className="px-4 py-1">{item.quantity}</td>
              <td className="px-4 py-1">{item.price}</td>
              <td className="px-4 py-1">{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BomTable;
