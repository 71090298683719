import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import closeIcon from "../assets/icons/close.svg";
import {
  getUserProfileDetails,
  patchUserProfileDetails,
} from "../utils/userProfile";

import toast from "react-hot-toast";
import { makeSessionRequest } from "../utils/makeSessionRequest";

export default function ProfileSettingsForm({
  show,
  closeForm,
}: {
  show: boolean;
  closeForm: () => void;
}) {
  const [profileDetails, setProfileDetails] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const profileInfo = await getUserProfileDetails();
        setProfileDetails({
          first_name: profileInfo?.first_name || "",
          last_name: profileInfo?.last_name || "",
          designation: profileInfo?.custom_data?.designation || "",
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Unable to fetch profile details");
      }
    })();
  }, [show]);

  const handleChange = (e: any) => {
    if (profileDetails) {
      setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
    } else {
      setProfileDetails({ [e.target.name]: e.target.value });
    }
  };

  const onSave = async (e: any) => {
    e.preventDefault();

    const patchData = {
      first_name: profileDetails.first_name,
      last_name: profileDetails.last_name,
      custom_data: { designation: profileDetails?.designation },
    };
    try {
      setIsSaving(true);
      await patchUserProfileDetails(patchData);
      toast.success("Profile details updated");
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      toast.error("Failed to save profile details");
    }
  };
  return (
    <div>
      <Modal show={show} onClose={() => null} type="profile">
        <form
          className="p-2 w-96 flex flex-col items-center"
          style={{ maxHeight: "85vh" }}
        >
          <div className="flex mb-4 w-full">
            <h1 className="font-bold text-lg ">Profile Settings</h1>

            <img
              className="ml-auto cursor-pointer"
              onClick={() => {
                if (!isLoading || !isSaving) {
                  closeForm();
                  makeSessionRequest("closed_quotation_modal");
                }
              }}
              src={closeIcon}
              alt="close"
            />
          </div>

          <div className="w-full flex flex-col">
            <p className="mb-2">First name: </p>
            <input
              className="px-4 py-2 w-full focus:outline-none border mb-4"
              name="first_name"
              type="text"
              value={profileDetails?.first_name}
              onChange={handleChange}
              disabled={isLoading}
              placeholder="Enter first name"
            />
            <p className="mb-2">Last name: </p>
            <input
              className="px-4 py-2 w-full focus:outline-none border mb-4"
              name="last_name"
              type="text"
              value={profileDetails?.last_name}
              onChange={handleChange}
              disabled={isLoading}
              placeholder="Enter last name"
            />
            <p className="mb-2">Designation: </p>
            <input
              className="px-4 py-2 w-full focus:outline-none border mb-4"
              name="designation"
              type="text"
              value={profileDetails?.designation}
              onChange={handleChange}
              disabled={isLoading}
              placeholder="Enter designation"
            />

            <button
              disabled={isLoading}
              className={`tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors ${
                isLoading || isSaving ? "opacity-30" : ""
              }`}
              onClick={onSave}
            >
              {isLoading ? "Loading..." : isSaving ? "SAVING...." : "SAVE"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
