import React, { useEffect } from "react";
import ReactDOM from "react-dom";

export default function Modal({
  children,
  onClose,
  show,
  type,
}: {
  show: boolean;
  children: any;
  onClose: () => void;
  type?: string;
}) {
  useEffect(() => {
    let element: any;
    switch (type) {
      case "quote":
        element = document.getElementById("QuotationModal");
        break;
      case "pricing":
        element = document.getElementById("PricingModal");
        break;
      case "profile":
        element = document.getElementById("ProfileModal");
        break;
      default:
        element = document.getElementById("Modal");
        break;
    }
    if (show) {
      ReactDOM.render(
        <React.StrictMode>
          <div
            onClick={(e: any) => {
              if (e.target.classList.contains("modal-container")) {
                onClose();
              }
            }}
            className="modal-container absolute flex justify-center items-center h-full w-full bg-transparent z-30"
            style={{ backdropFilter: "blur(3px)" }}
          >
            <div className=" bg-white border p-4 pb-4 rounded-md shadow-xl">
              {children}
            </div>
          </div>
        </React.StrictMode>,
        element
      );
    } else {
      element && ReactDOM.unmountComponentAtNode(element);
    }
    return () => {};
  }, [children, onClose, show, type]);

  return <div></div>;
}
