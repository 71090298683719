import axios from "axios";

export const makeSessionRequest = async (event: string, parameters?: any) => {
  try {
    const sessionId = localStorage.getItem("sessionId");

    const userString = localStorage.getItem("user");
    const currentUser = userString ? JSON.parse(userString) : undefined;

    if (currentUser) {
      axios.post(process.env.REACT_APP_HELLOAR_API + "app/session/event", {
        event: event,
        application_id: process.env.REACT_APP_APPLICATION_ID,
        account_id: currentUser.account_id,
        account_session_id: currentUser.session_id,
        viewer_id: process.env.REACT_APP_VIEWER_ID,
        session_id: sessionId,
        parameters: parameters,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
