import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  configDataConstants,
  configurationConstants,
} from "../store/actions/types";
import { Feature, Option, Rule, UserConfiguration } from "../types/types";

const LegsFeatureName = "Legs";
const CClusterLegsFeatureName = "C Cluster Legs";
const HandRestFeatureName = "Hand Rest";

const WireManagement2 = "Wire Management";
const wmsr = "Wire Management (Side Radius)";
const wm1 = "Wire Management (Plain)";
const wm2 = "Wire Management (Side Radius)";
const wmrw = "Wire Management (Reverse Waterfall)";
const wmp = "Wire Management (Plain)";
const BaseFeatureName = "Base";
const btable = "Below Table TableTop Tile";
const PrivacyFeatureName = "Privacy";
const btt = "Below Table Table Top Tile";
const attp = "Above table top tile/privacy";
const AccessoriesFeatureName = "Accessories";
const MetalFeatureName = "Powder Coat";
const pco = "Privacy Color Options";
const Pcolor = "Plastic Color";
const MechanismFeatureName = "Mechanism";
const ColorOptionsPrivacy = "Color Options Privacy";
const GlassesFeatureName = "Glass Color";
const FabricFeatureName = "Fabric Color";
const WoodFeatureName = "Laminate";
const bw = "Bottom Laminate";
const lc = "Prelam Leg Laminate";

const WheelsFeatureName = "Wheels";
const PedestalFeatureName = "Pedestal";
const tt = "Table Top";
const PedestalFeatureHandleName = "Handle";
const Storages = "Storages";
const PedestalFeatureLockName = "Lock";
const System = "System";
const Dimensions = "Dimensions";
const PedestalFeatureCushionName = "Cushion";
const PedestalFeatureDrawerWoodName = "Pedestal Facia";
const sf = "Storage Facia";
const ss = "Storage Side";
const PedestalFeatureSideWoodName = "Pedestal Side";
const PedestalFeatureTopWoodName = "Pedestal Top";
const PedestalFeatureFabricName = "Fabric";
const SideTableFeatureName = "Pedestals";
const LegColorFeatureName = "Color Options";
const useUserConfiguration = () => {
  const dispatch = useAppDispatch();

  const userConfiguration2: any = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );
  console.log(userConfiguration2);
  const specs: Feature[] = useAppSelector((state) => state.configData.current);
  const specsOriginal: Feature[] = useAppSelector(
    (state) => state.configData.original
  );

  const enableDisableOptions = (option: Option) => {
    const { disableFeatures, enableFeatures, disableOptions, enableOptions } =
      option;
    console.log(option);
    if (disableFeatures) {
      dispatch({
        type: configurationConstants.DISABLE_FEATURES,
        payload: disableFeatures,
      });
    }

    if (enableFeatures) {
      dispatch({
        type: configurationConstants.ENABLE_FEATURES,
        payload: enableFeatures,
      });
    }
    console.log(disableOptions);
    if (disableOptions) {
      dispatch({
        type: configurationConstants.DISABLE_OPTIONS,
        payload: disableOptions,
      });
    }

    if (enableOptions) {
      dispatch({
        type: configurationConstants.ENABLE_OPTIONS,
        payload: enableOptions,
      });
    }
  };

  const removeDependentFeatures = (value: Feature & { option: Option }) => {
    (value.option?.options as Feature[] | undefined)?.forEach((subFeature) => {
      const option =
        userConfiguration2[subFeature.name as keyof UserConfiguration];
      if (option) {
        !(subFeature.options as Option[])
          .map((_option) => _option.name)
          .includes(option) &&
          dispatch({
            type: configurationConstants.DELETE_PROPERTY,
            payload: subFeature.name,
          });
      }
    });
  };

  const updateUserConfiguration = (
    featureName: string,
    value: Feature & { option: Option }
  ) => {
    console.log(featureName, value);
    enableDisableOptions(value.option);
    removeDependentFeatures(value);
    switch (featureName) {
      case "System":
        const newConfigData = specsOriginal.map((item: any) => {
          const filteredOptions = item.options
            .filter((option: any) => {
              if (item.name === "System") return true;
              return option.name === value.name;
            })
            .map((option: any) => ({ ...option }));
          if (item.name === "System")
            return { ...item, options: filteredOptions };
          return {
            ...item,
            options: filteredOptions[0]?.options,
            rules: filteredOptions[0]?.rules,
          };
        });
        dispatch({
          type: configDataConstants.SET_CURRENT_CONFIG_DATA,
          payload: newConfigData,
        });
        console.log(value.option.Category);
        if (
          value.option.Category == "Linear Workstation"
        ) {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: {
              type: value.name,
              [value.name]: value.option.name,
              noOfSeats: value.option.size,
              ...(value.option.id && { id: value.option.id }),
              ...(value.option?.name && { name: value.option?.name }),
              ...(value.option.Length && { Length: value.option.Length }),
              ...(value.option.Height && { Height: value.option.Height }),
              ...(value.option.Depth && { Depth: value.option.Depth }),
              ...(value.option.Dia && { Dia: value.option.Dia }),
              ...(value.option.table && { table: value.option.table }),
              ...(value.option.Category && { Category: value.option.Category }),

              ...(value.option["Table Top Options"] && {
                "Table Top Options": value.option["Table Top Options"],
              }),
            },
          });
        } else {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: {
              type: value.name,
              [value.name]: value.option.name,
              noOfSeats: value.option.size,
              ...(value.option.id && { id: value.option.id }),
              ...(value.option?.name && { name: value.option?.name }),
              ...(value.option.Length && { Length: value.option.Length }),
              ...(value.option.Height && { Height: value.option.Height }),
              ...(value.option.Depth && { Depth: value.option.Depth }),
              ...(value.option.Dia && { Dia: value.option.Dia }),
              ...(value.option.table && { table: value.option.table }),
              ...(value.option.Category && { Category: value.option.Category }),
              ...(value.option["Table Top Options"] && {
                "Table Top Options": value.option["Table Top Options"],})
            },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Fabric Panel Accessories": null },
        });
        break;
      case "Dimensions":
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.value },
        });
        break;
      // case AccessoriesFeatureName:
      case MetalFeatureName:
      case MechanismFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case WoodFeatureName:
      case bw:
      case lc:
      case WheelsFeatureName:
      case Pcolor:
      case PedestalFeatureLockName:
      case btt:
      case PedestalFeatureCushionName:
      case PedestalFeatureDrawerWoodName:
      case sf:
      case ss:
      case wmrw:
      case wmp:
      case wmsr:
      case PedestalFeatureSideWoodName:
      case PedestalFeatureTopWoodName:
      case PedestalFeatureFabricName:
      case LegColorFeatureName:
      case ColorOptionsPrivacy:
      case PedestalFeatureName:

      case SideTableFeatureName:
        let _value = value.option.name;

        if (value.multiSelect) {
          _value = userConfiguration2[featureName]?.includes(value.option.name)
            ? userConfiguration2[featureName].filter(
                (_value: string) => _value !== value.option.name
              )
            : [...(userConfiguration2[featureName] || []), value.option.name];
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [featureName]: _value },
        });

        break;

      case LegsFeatureName:
        console.log(value.name);
        if (value.name == "Leg") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Management": null },
          });
          return;
        } else {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Side Radius Raceway Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Plain Raceway Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Reverse Waterfall Raceway Options": null },
          });
          return;
        }
        break;
      case CClusterLegsFeatureName:
        console.log(value.name);
        if (value.name == "C Cluster Legs") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Management": null },
          });
          return;
        } else {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Side Radius Raceway Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Plain Raceway Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Reverse Waterfall Raceway Options": null },
          });
          return;
        }
        break;

      case BaseFeatureName:
      case Storages:
      case HandRestFeatureName:
        if (value.option.name == "Return Table Top") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { Legs: null },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Option": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimensions Option": null },
          });
        }
        if (value.option.name == "None") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { Back: null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Table Top Leg": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Option": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimensions Option": null },
          });
        }
        if (value.option.name == "Return Storage") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Table Top Leg": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Option": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimensions Option": null },
          });
        }
        if (value.option.name == "Return Storage 2") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Table Top Leg": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Options": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimension Option": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Return Dimensions Option": null },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });

        return;
      case tt:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Side Radius Raceway Options": null },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Plain Raceway Options": null },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Plain Wire Management's Dimensions": null },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Side Radius Wire Management's Dimensions": null },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { "Reverse Waterfall Raceway Options": null },
        });

        return;
      case PrivacyFeatureName:
        if (value.option.name == "Hung Panel") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Side Modesty": "None" },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        return;
      case PedestalFeatureHandleName:
      case Dimensions:
      case wm1:
      case wm2:
      case "System":
      case attp:
      case btable:
      case pco:
      case WireManagement2:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });

        // dispatch({
        //   type: configurationConstants.UPDATE_CONFIGURATION2,
        //   payload: {
        //     [AccessoriesFeatureName]: [],
        //   },
        // });
        return;
      case AccessoriesFeatureName:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        // dispatch({
        //   type: configurationConstants.UPDATE_CONFIGURATION2,
        //   payload: {
        //     [AccessoriesFeatureName]: [],
        //   },
        // });
        return;

      default:
        break;
    }
  };

  const getSelectedOption = (
    featureName: string,
    subFeatureName: string
  ): Option[] => {
    const Feature: Feature | undefined = specs.find(
      (x: any) => x.name === featureName
    );
    if (!Feature) return [];
    switch (featureName) {
      case "System":
        if (subFeatureName !== userConfiguration2.type) {
          return [];
        }
        try {
          const system = Feature;

          const sharingOrNonSharing = (system.options as Feature[]).find(
            (x) => x.name === userConfiguration2.type
          );

          const selectedOption = (
            sharingOrNonSharing?.options as Option[]
          ).find((option) => option.size === userConfiguration2.noOfSeats);

          return selectedOption ? [selectedOption] : [];
        } catch (error) {
          return [];
        }

      case "Dimensions":
        const subFeature: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        const selectedOption2 = (
          subFeature?.options as Option[] | undefined
        )?.find(
          (option) =>
            option.value === userConfiguration2[subFeature?.name || ""]
        );

        return selectedOption2 ? [selectedOption2] : [];

      // case AccessoriesFeatureName:
      case PedestalFeatureName:

      case SideTableFeatureName:
      case ColorOptionsPrivacy:
      case MetalFeatureName:
      case MechanismFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case WoodFeatureName:
      case WheelsFeatureName:
      case bw:
      case lc:
      case btt:
      case Pcolor:
      case PedestalFeatureLockName:
      case PedestalFeatureCushionName:
      case PedestalFeatureDrawerWoodName:
      case sf:
      case ss:
      case wmrw:
      case wmp:
      case wmsr:
      case PedestalFeatureSideWoodName:
      case PedestalFeatureTopWoodName:
      case PedestalFeatureFabricName:
        if (Feature.multiSelect) {
          const selectedOption3 = (Feature.options as Option[]).filter(
            (x: any) => userConfiguration2[Feature.name]?.includes(x.name)
          );
          return selectedOption3;
        } else {
          const selectedOption3 = (
            Feature.options as Option[] | undefined
          )?.find((option) => option.name === userConfiguration2[Feature.name]);
          return selectedOption3 ? [selectedOption3] : [];
        }
      case pco:
      case PrivacyFeatureName:
      case btable:
      case WireManagement2:
      case AccessoriesFeatureName:

      case BaseFeatureName:
      case PedestalFeatureHandleName:
      case Dimensions:
      case tt:
      case wm1:
      case wm2:
      case attp:
      case HandRestFeatureName:
      case "System":
      case LegsFeatureName:
      case CClusterLegsFeatureName:
      case Storages:
        let subFeature2: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        if (!subFeature2) {
          Feature.options?.forEach((x) => {
            if (getSelectedOption(featureName, x.name) instanceof Array) {
              getSelectedOption(featureName, x.name)?.forEach((option) => {
                option?.options?.forEach((z) => {
                  if (z.name === subFeatureName) {
                    subFeature2 = z as Option;
                  }
                });
              });
            }
          });
        }
        const selectedOption4 = (
          subFeature2?.options as Option[] | undefined
        )?.find((option) => {
          return option.name === userConfiguration2[subFeature2?.name || ""];
        });
        return selectedOption4 ? [selectedOption4] : [];

      default:
        return [];
    }
  };

  const getRules = () => {
    const rules: { [key: string]: Rule[] } = {};
    Object.keys(userConfiguration2).forEach((key) => {
      switch (key) {
        // case AccessoriesFeatureName:
        case MetalFeatureName:
        case MechanismFeatureName:
        case GlassesFeatureName:
        case FabricFeatureName:
        case WoodFeatureName:
        case bw:
        case lc:
        case WheelsFeatureName:
        case btt:
        case Pcolor:
        case PedestalFeatureLockName:
        case PedestalFeatureCushionName:
        case PedestalFeatureDrawerWoodName:
        case PedestalFeatureSideWoodName:
        case sf:
        case ss:
        case wmrw:
        case wmp:
        case wmsr:
        case PedestalFeatureTopWoodName:
        case PedestalFeatureFabricName:
        case PedestalFeatureName:

        case SideTableFeatureName:
          const legs = specs.find((x: any) => x.name === key);
          const r1 = legs?.rules;
          const r2 = getSelectedOption(key, "")?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );

          rules[key] = [...(r1 || []), ...(r2 || [])];
          //append screen size to nodes

          // if (key === AccessoriesFeatureName) {
          //   const mainScreen = getSelectedOption(
          //     PrivacyFeatureName,
          //     "Main screen"
          //   );
          //   if (mainScreen && mainScreen.length > 0) {
          //     let screenSize =
          //       mainScreen &&
          //       mainScreen[0]?.name?.substr(mainScreen[0]?.name.length - 4, 4);
          //     screenSize = screenSize?.split("H")[0];

          //     rules[key] = rules[key].map((rule) => {
          //       return {
          //         ...rule,
          //         node: rule.node.map(
          //           (nodeName) => nodeName + "_" + screenSize
          //         ),
          //       };
          //     });
          //   } else {
          //     rules[key] = rules[key].map((rule) => {
          //       return {
          //         ...rule,
          //         node: rule.node.map((nodeName) => nodeName),
          //       };
          //     });
          //   }
          // }

          break;

        case "Base":
          // case "Wire Entry":
          const emtBase = getSelectedOption(BaseFeatureName, "Raceway");
          const emtOptionBase =
            emtBase &&
            (emtBase[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r5Base = emtOptionBase?.rules as Rule[] | undefined;
          const r6Base = getSelectedOption(BaseFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[BaseFeatureName] = [
            ...(rules[BaseFeatureName] || []),
            ...(r5Base || []),
            ...(r6Base || []),
          ];
          return;
        case "Legs":
        case "Non Sharing Legs":
        case "Sharing Legs":
        case "Pedestal's Legs":
        case "Plan Legs":
        case "Plan Legs(NS)":
          const emtLeg = getSelectedOption(LegsFeatureName, "Legs");
          const emtOptionLeg =
            emtLeg &&
            (emtLeg[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r7 = emtOptionLeg?.rules as Rule[] | undefined;
          const r8 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r7 || []),
            ...(r8 || []),
          ];
          return;
        case "Leg":
          const emtLegs = getSelectedOption(LegsFeatureName, "Legs");
          const emtOptionLegs =
            emtLegs &&
            (emtLegs[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r7s = emtOptionLegs?.rules as Rule[] | undefined;
          const r8s = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r7s || []),
            ...(r8s || []),
          ];
          return;
        case "C Cluster Legs":
        case "C Cluster Legs Sharing":
        case "Plan Legs":
        case "Plan Legs(NS)":
          const emtCClusterLegs = getSelectedOption(
            CClusterLegsFeatureName,
            "C Cluster Legs"
          );
          const emtOptionCClusterLegs =
            emtCClusterLegs &&
            (emtCClusterLegs[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const ruleU = emtOptionCClusterLegs?.rules as Rule[] | undefined;
          const ruleV = getSelectedOption(CClusterLegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[CClusterLegsFeatureName] = [
            ...(rules[CClusterLegsFeatureName] || []),
            ...(ruleU || []),
            ...(ruleV || []),
          ];
          return;

        case "Hand Rest":
          const emtHR = getSelectedOption(HandRestFeatureName, "Legs");
          const emtOptionHR =
            emtHR &&
            (emtHR[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r7HR = emtOptionHR?.rules as Rule[] | undefined;
          const r8HR = getSelectedOption(HandRestFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[HandRestFeatureName] = [
            ...(rules[HandRestFeatureName] || []),
            ...(r7HR || []),
            ...(r8HR || []),
          ];
          return;
        case "Pedestal":
          const emtPed = getSelectedOption("Pedestal", "Pedestal");
          const emtOptionPed =
            emtPed &&
            (emtPed[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r77 = emtOptionPed?.rules as Rule[] | undefined;
          const r88 = getSelectedOption("Pedestal", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules["Pedestal"] || []),
            ...(r77 || []),
            ...(r88 || []),
          ];
          return;

        case LegColorFeatureName:
          const emtLegColor = getSelectedOption(
            LegsFeatureName,
            LegColorFeatureName
          );
          const emtOptionLegColor =
            emtLegColor &&
            (emtLegColor[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r11 = emtOptionLegColor?.rules as Rule[] | undefined;
          const r12 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r11 || []),
            ...(r12 || []),
          ];
          return;

        case ColorOptionsPrivacy:
          const fabricColor = getSelectedOption(
            LegsFeatureName,
            LegColorFeatureName
          );
          const fabricOptionLegColor =
            fabricColor &&
            (fabricColor[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r111 = fabricOptionLegColor?.rules as Rule[] | undefined;
          const r122 = getSelectedOption("Privacy", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules["Privacy"] = [
            ...(rules["Privacy"] || []),
            ...(r111 || []),
            ...(r122 || []),
          ];
          return;
        case "SHARING":
        case "NON SHARING":
        case "WorkStation":
        case "Three Seater":
          console.log("came", key);
          const privacyppT = specs.find((x) => x.name === "System");
          const msppT = (privacyppT?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          console.log(msppT);
          const r9ppT = msppT?.rules as Rule[] | undefined;
          const r10ppT = getSelectedOption("System", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules["System"] = [
            ...(rules["System"] || []),
            ...(r9ppT || []),
            ...(r10ppT || []),
          ];
          return;
        case "Handle":
        case "Handle's Color":
          console.log("came");
          const privacyy = specs.find(
            (x) => x.name === PedestalFeatureHandleName
          );
          const mss = (privacyy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r1111 = mss?.rules as Rule[] | undefined;
          const r1222 = getSelectedOption(
            PedestalFeatureHandleName,
            key
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[PedestalFeatureHandleName] = [
            ...(rules[PedestalFeatureHandleName] || []),
            ...(r1111 || []),
            ...(r1222 || []),
          ];
          return;
        case "Plain Raceway OptionS":
          const privacyyWwm1 = specs.find((x) => x.name === wm1);
          const mssWwm1 = (privacyyWwm1?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r1111Wwm1 = mssWwm1?.rules as Rule[] | undefined;
          const r1222Wwm1 = getSelectedOption(wm1, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[wm1] = [
            ...(rules[wm1] || []),
            ...(r1111Wwm1 || []),
            ...(r1222Wwm1 || []),
          ];
          return;
        case "Side Radius Raceway OptionS":
          const privacyyWwm2 = specs.find((x) => x.name === wm2);
          const mssWwm2 = (privacyyWwm2?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r1111Wwm2 = mssWwm2?.rules as Rule[] | undefined;
          const r1222Wwm2 = getSelectedOption(wm2, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[wm2] = [
            ...(rules[wm2] || []),
            ...(r1111Wwm2 || []),
            ...(r1222Wwm2 || []),
          ];
          return;
        case "Length":
        case "Depth":
          console.log("came", key);
          const privacyyW = specs.find((x) => x.name === Dimensions);
          const mssW = (privacyyW?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r1111W = mssW?.rules as Rule[] | undefined;
          const r1222W = getSelectedOption(Dimensions, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[Dimensions] = [
            ...(rules[Dimensions] || []),
            ...(r1111W || []),
            ...(r1222W || []),
          ];
          return;
        case "Return":
        case "Back":
        case "Return Table Top Leg":
          const privacyppb = specs.find((x) => x.name === Storages);
          const msppb = (privacyppb?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9ppb = msppb?.rules as Rule[] | undefined;
          const r10ppb = getSelectedOption(Storages, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[Storages] = [
            ...(rules[Storages] || []),
            ...(r9ppb || []),
            ...(r10ppb || []),
          ];
          return;
        case "Fabric's Color":
        case "Glass's Color":
          console.log("came", key);
          const privacyyWpco = specs.find((x) => x.name === pco);
          const mssWpco = (privacyyWpco?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r1111Wpco = mssWpco?.rules as Rule[] | undefined;
          const r1222Wpco = getSelectedOption(pco, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[pco] = [
            ...(rules[pco] || []),
            ...(r1111Wpco || []),
            ...(r1222Wpco || []),
          ];
          return;

        case "Table Top Options":
        case "Table Top Options Sharing":
          const privacyppt = specs.find((x) => x.name === tt);
          const msppt = (privacyppt?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9ppt = msppt?.rules as Rule[] | undefined;
          const r10ppt = getSelectedOption(tt, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[tt] = [
            ...(rules[tt] || []),
            ...(r9ppt || []),
            ...(r10ppt || []),
          ];
          return;
        case "Wood Options":
        case "Main screen":
        case "Return screen":
        case "Main Modesty":
        case "MID Modesty":
        case "Side Panel":
        case "Left Side Panel Options":
        case "Left Side Panel Glass Color":
        case "Left Side Panel Laminate Color":
        case "Left Side Panel Fabric Color":
        case "Right Side Panel Options":
        case "Right Side Panel Glass Color":
        case "Right Side Panel Laminate Color":
        case "Right Side Panel Fabric Color":
        case "Side Modesty":
        case "Mid Modesty":
        case "Side Modesty (S)":
        case "Mid Modesty (S)":
        case "Side Modesty (NS)":
        case "Mid Modesty (NS)":
        case "Mid Panel":
        case "Mid Panel Options":
        case "Mid Panel Glass Color":
        case "Mid Panel Fabric Color":
        case "Mid Panel Laminate Color":
        case "Privacy Panels":
        case "Privacy Panels (S)":
        case "Privacy Panels (NS)":
          const privacypp = specs.find((x) => x.name === PrivacyFeatureName);
          const mspp = (privacypp?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9pp = mspp?.rules as Rule[] | undefined;
          const r10pp = getSelectedOption(PrivacyFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[PrivacyFeatureName] = [
            ...(rules[PrivacyFeatureName] || []),
            ...(r9pp || []),
            ...(r10pp || []),
          ];
          return;
        case "Return Panels":
        case "Return Panels (S)":
        case "Return Panels (NS)":
        case "Middle Panels":
        case "Main Panels":
        case "Metal Options":
        case "Main Screen":
        case "End to End Panel Options":
        case "Aluminium Panel Options":
        case "Fabric Panel Accessories":
        case "End to End Panel Options (S)":
        case "Aluminium Panel Options (S)":
        case "Fabric Panel Accessories (S)":
        case "End to End Panel Options (NS)":
        case "Aluminium Panel Options (NS)":
        case "Fabric Panel Accessories (NS)":
          const privacy = specs.find((x) => x.name === PrivacyFeatureName);
          const ms = (privacy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9 = ms?.rules as Rule[] | undefined;
          const r10 = getSelectedOption(PrivacyFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[PrivacyFeatureName] = [
            ...(rules[PrivacyFeatureName] || []),
            ...(r9 || []),
            ...(r10 || []),
          ];
          return;
        case "Return Panel":
        case "Middle Panel":
        case "Return Frame Pannel Dimension":
        case "Main Panel":
        case "Metal Option":
        case "Main Screens":
        case "Wood Option":
        case "Metal Option":
          console.log("hey");
          const privacyyy = specs.find((x) => x.name === attp);
          const msyy = (privacyyy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9yy = msyy?.rules as Rule[] | undefined;
          const r10yy = getSelectedOption(attp, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[attp] = [
            ...(rules[attp] || []),
            ...(r9yy || []),
            ...(r10yy || []),
          ];
          return;

        ////
        case "Plain Raceway Options":
        case "Reverse Waterfall Raceway Options":
        case "Side Radius Raceway Options":
        case "Raceway":
        case "Raceway (s)":
        case "Raceway Sharing":
        case "Wire Management's Dimensions Non Sharing":
        case "Wire Management":
        case "Wire Management's Dimensions Sharing":
        case "Wire Management's Dimensions":
        case "Raceway Sharing":
        case "Raceway Non Sharing":
          console.log("came");
          const privacyw = specs.find((x) => x.name === WireManagement2);
          const msw = (privacyw?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9w = msw?.rules as Rule[] | undefined;
          const r10w = getSelectedOption(WireManagement2, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[WireManagement2] = [
            ...(rules[WireManagement2] || []),
            ...(r9w || []),
            ...(r10w || []),
          ];
          return;

        case "Below Table TableTop Tile":
        case "Below Table TableTop Tile Colors":
          const privacyppbt = specs.find((x) => x.name === btable);
          const msppbt = (privacyppbt?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9ppbt = msppbt?.rules as Rule[] | undefined;
          const r10ppbt = getSelectedOption(btable, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[btable] = [
            ...(rules[btable] || []),
            ...(r9ppbt || []),
            ...(r10ppbt || []),
          ];
          return;
        case "Keyboard Trays":
        case "CPU Stands":
        case "Keyboard Trays (S)":
        case "CPU Stands (S)":
        case "Keyboard Trays (NS)":
        case "CPU Stands (NS)":
          const accessories = specs.find(
            (x) => x.name === AccessoriesFeatureName
          );
          const ams = (accessories?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const ar9 = ams?.rules as Rule[] | undefined;
          const ar10 = getSelectedOption(AccessoriesFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[AccessoriesFeatureName] = [
            ...(rules[AccessoriesFeatureName] || []),
            ...(ar9 || []),
            ...(ar10 || []),
          ];
          return;

        default:
          break;
      }
    });
    const evaluateMultipleConditions = (
      multipleConditions: any,
      userConfig: any
    ): boolean => {
      console.log(userConfig, multipleConditions);
      let result = true;
      let currentOperator: "AND" | "OR" = "AND";

      for (let i = 0; i < multipleConditions.length; i++) {
        const condition = multipleConditions[i];

        if ("operator" in condition) {
          currentOperator = condition.operator;
        } else {
          const conditionMet = condition.values.includes(
            userConfig[condition.feature]
          );

          if (currentOperator === "AND") {
            result = result && conditionMet;
          } else if (currentOperator === "OR") {
            result = result || conditionMet;
          }
        }
      }

      return result;
    };
    const parseConditions = (rules: Rule[]) => {
      console.log(rules);
      let cc = rules.filter((rule: any) => {
        let filter = true;
        console.log(rule.condition);
        rule.condition?.forEach((condition: any) => {
          console.log(condition);
          console.log(condition.feature);
          console.log(userConfiguration2);
          condition.values.includes(userConfiguration2[condition.feature])
            ? (filter = true)
            : (filter = false);
        });
        rule.conditionAnd?.forEach((condition: any) => {
          console.log(condition);
          console.log(condition.feature1, " ", condition.feature2);
          console.log(userConfiguration2);
          console.log(
            condition.values1.includes(
              userConfiguration2[condition.feature1]
            ) &&
              condition.values2.includes(userConfiguration2[condition.feature2])
          );
          condition.values1.includes(userConfiguration2[condition.feature1]) &&
          condition.values2.includes(userConfiguration2[condition.feature2])
            ? (filter = true)
            : (filter = false);
        });

        if (rule.multipleConditions) {
          console.log(rule.multipleConditions);
          console.log(userConfiguration2);

          filter = evaluateMultipleConditions(
            rule.multipleConditions,
            userConfiguration2
          );
          console.log(filter, rule);
        }

        return filter;
      });
      console.log(cc);
      return cc;
    };
    Object.keys(rules).forEach((key) => {
      console.log(parseConditions(rules[key]), rules[key], key);

      rules[key] = parseConditions(rules[key]);
    });
    console.log(rules);
    return rules;
  };

  return {
    updateUserConfiguration,
    userConfiguration2,
    getSelectedOption,
    getRules,
  };
};

export default useUserConfiguration;
