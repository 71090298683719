import { getDefaultCompilerOptions } from "typescript";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

const hideModestiesRules = [
  {
    type: "hide",
    node: ["modesty_wood_left", "modesty_perforated_left"],
  },
];
export const ConfData3 = Object.freeze([
  //basically for linear workstations
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,

            thumbnail: "assets/system/thumbnails/sharing/2.png",
            productId: "6639e058a93af152158962d5",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/4.png",
            productId: "66387ed4143a159bc27b0804",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/6.png",
            productId: "663a2eeca998c292fbce4fc2",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Eight Seater",
            size: 8,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/8.png",
            productId: "663df012c4c2f233837e0aaa",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Ten Seater",
            size: 10,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/10.png",
            productId: "663a2fcba998c292fbce50f4",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Twelve Seater",
            size: 12,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/12.png",
            productId: "663a313b7a4ac60733d547b4",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Fourteen Seater",
            size: 14,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/14.png",
            productId: "663b68980f2126a99ed3e67f",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Sixteen Seater",
            size: 16,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/16.png",
            productId: "663b6cdbd0d5be25b095b86f",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Eighteen Seater",
            size: 18,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/18.png",
            productId: "663b6fb8aaf14978c9124db9",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Twenty Seater",
            size: 20,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/20.png",
            productId: "663b712404be1eb185235c63",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "TwentyTwo Seater",
            size: 22,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/22.png",
            productId: "663b749dad9a3306c4e8b89c",
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
          {
            name: "Twenty Four Seater",
            size: 24,
            "Table Top Options": "Plain(S)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/sharing/24.png",
            productId: "663b75ca66538a5ad2caf75b",
            disableOptions: ["Mid Prelam", "Mid Metal"],
            disableFeatures: [
              "Aluminium Panel Options (S)",
              "Fabric Panel Accessories (S)",
              "End to End Panel Options (S)",
              "Privacy Panel Height(S)",
              "Return Panel Height(S)",
            ],

            enableOptions: [],
            tableTop: [""],
            Category: "Linear Workstation",
            intermediateLegs: [],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,
        disableFeatures: ["Wire Management's Dimensions Non Sharing"],
        options: [
          {
            name: "One Seater",
            size: 1,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/1.png",
            productId: "663b81a3f713421d44c83ab0",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Two Seater",
            size: 2,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/2.png",
            productId: "6644a16cf06b83ba86854156",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Three Seater",
            size: 3,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/3.png",
            productId: "6644b323895e902226f78946",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Four Seater",
            size: 4,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/4.png",
            productId: "6644b428194dbdd146c88aa5",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            Category: "Linear Workstation",
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Five Seater",
            size: 5,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/5.png",
            productId: "6644b76f0d7755c3ed9599a1",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Six Seater",
            size: 6,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/6.png",
            productId: "66456f0726b5423a88f34cfb",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            Category: "Linear Workstation",
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Seven Seater",
            size: 7,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/7.png",
            productId: "6645748ec49646750589330f",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Eight Seater",
            size: 8,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/8.png",
            productId: "6645767e73a2f88731c24163",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Nine Seater",
            size: 9,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/9.png",
            productId: "66457a26265f6f6488a9bbf4",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Ten Seater",
            size: 10,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/10.png",
            productId: "66457c730328516d75718e31",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Eleven Seater",
            size: 11,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/11.png",
            productId: "66457e873d1c8d5d64ec8be5",
            tableTop: ["table_top_nonshared"],
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
          {
            name: "Twelve Seater",
            size: 12,
            "Table Top Options": "Plain(NS)",
            Length: 1200,
            Depth: 600,
            thumbnail: "assets/system/thumbnails/non/12.png",
            productId: "66458030a13d9647ebb709a3",
            disableFeatures: [
              "Aluminium Panel Options (NS)",
              "Fabric Panel Accessories (NS)",
              "End to End Panel Options (NS)",
              "Privacy Panel Height(NS)",
              "Return Panel Height(NS)",
            ],
            Category: "Linear Workstation",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
            disableOptions: ["Mid Prelam", "Mid Metal"],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Sharing Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/f92a08a8-8a14-4380-969d-75abd9bf1113/17_6_2024/1721208457305/leftsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/6e3b1ec4-69c8-4fe0-867e-139f5bc9d15b/17_6_2024/1721208402166/rightsharing.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },

                  ...hideModestiesRules,
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "ioconnect",
                    ],
                  },
                ],
              },
              {
                name: "Nova",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                // enableFeatures: ["Color Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7b1c28fb-8af2-4413-82c5-d3df1bff477d/17_6_2024/1721208600560/rightsharing.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/146fb2e4-847a-4ee8-a069-12b9f4abff50/17_6_2024/1721208639991/leftsharing.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],

                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/ddfd20e9-2c52-4b78-93a6-2ddcb8ff6975/17_6_2024/1721209031924/leftsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/3751c421-f96a-47f9-b3e6-b2c00dffc93b/17_6_2024/1721208969393/rightsharing.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "ioconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/45dd0487-17e7-49bd-9a97-e874978b7872/17_6_2024/1721208863362/leftsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a26ae870-63b7-4cd3-9c9e-c224f618c6fe/17_6_2024/1721208810623/rightsharing.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "workconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["ioconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",

                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/9c2978b4-dd22-4d13-ba7f-da49cb4a4e23/17_6_2024/1721208733416/leftsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/6e8b2ad0-ba43-45f4-9216-0ca8e37cf5b7/17_6_2024/1721208700104/rightsharing.glb",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Non Sharing Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                // enableFeatures: ["Color Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    // pid: "66387f0930180877c5875bb1",
                    pid: "https://content.helloviewer.io/uploads/a8ed7f9c-441b-4fd6-902b-404f7031556e/17_6_2024/1721212800707/leftnonsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/6637df37-0ca1-4d8d-8315-732a00df723a/17_6_2024/1721212760113/rightnonsharing.glb", //"6638d0447f3062d784ce6ab6",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: ["FlipUp@work"],
                  },

                  ...hideModestiesRules,
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "ioconnect",
                    ],
                  },
                ],
              },
              {
                name: "Nova",
                thumbnail: "assets/system/thumbnails/Parts/nova.png",
                // enableFeatures: ["Color Options"],
                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/1a370643-1b26-4b19-a53d-9905959383b9/17_6_2024/1721211285717/rightnonsharing.glb", //"6638ca9ccb40de82b2da90bb",//
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/818fa1dd-0e67-4677-a411-0edaf431b45b/17_6_2024/1721211354533/leftnonsharing.glb", //"6638e25215077125003fdfc9",//
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "@work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                // enableFeatures: ["Color Options"],

                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/3a163c15-5a1f-4c33-b76c-e320d1e7ae3f/17_6_2024/1721209514621/leftnonsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/82d2a863-3c96-4696-981c-d6ea26d16a7f/17_6_2024/1721209473430/rightnonsharing.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "ioconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["workconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "IO",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                // enableFeatures: ["Color Options"],
                disableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/e3860e05-a24f-46a0-bcd9-963bcb40bbc2/17_6_2024/1721210021592/leftnonsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/383b87e2-07e8-477f-8cbf-d713c62e8b0a/17_6_2024/1721209981867/rightnonsharing.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "prelammid",
                      "metalmodestymid",
                      "prelamleft",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "prelamright",
                      "workconnect",
                    ],
                  },
                  {
                    type: "show",
                    node: ["ioconnect"],
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",

                enableOptions: ["Keyboard and Mouse Tray", "Keyboard Tray"],
                // enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/1c6cab45-801d-4e74-9b44-ea68c5924eef/17_6_2024/1721212028074/leftnonsharing.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/d42fe7f1-5d3e-4d68-aacf-9dbcd53283be/17_6_2024/1721211859944/rightnonsharing.glb",
                  },
                  {
                    type: "show",
                    node: ["connecter"],
                  },
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid@work",
                      "prelammid@work",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                      "workconnect",
                      "FlipUp@work",
                      "ioconnect",
                    ],
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    default: "FlipUp",
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "Raceway Sharing",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              // {
              //   name: "None",
              //   thumbnail: "/assets/components/none.jpg",
              //   // options: [...boxraceway],
              //   // enableFeatures: ["Main Modesty"],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "Flip Up",
              //         "Raceway",
              //         "gorment2",
              //         "gorment2@work",
              //         "gorment@work",
              //         "FlipUp@work",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["Table_top"],
              //     },
              //   ],
              // },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],
                enableFeatures: ["Wire Management's Dimensions Sharing"],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope", "IO"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                disableFeatures: ["Wire Management's Dimensions Sharing"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment2",
                      "FlipUp@work",

                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "gorment",
                      "Table_top",
                      "connecter",
                      "mid_leg_gorment",
                    ],
                  },

                  {
                    type: hide,
                    node: [
                      "mid_leg",
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: applyMaterial,
                    node: ["GORMET"],
                    condition: [
                      {
                        feature: "Laminate",
                        values: [
                          "Mangfall Beech",
                          "Acacia",
                          "Urban Teak",
                          "SILVER GREY DIFFUSE",
                          "Cairo Walnut BaseColor",
                          "Maldau Acacia BaseColor",
                          "Plain DefaultMaterial BaseColor",
                          "Thansau Maple BaseColor",
                        ],
                      },
                    ],
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                disableFeatures: ["Wire Management's Dimensions Sharing"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Management's Dimensions Sharing",
            multiSelect: true,
            options: [
              {
                name: "300x145",
                required: false,
              },
              // {
              //   name: " 400 x 145mm ",
              //   required: false,
              // },
              {
                name: "450x145",
                required: false,
              },
              {
                name: "500x145",
                required: false,
              },
            ],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "Raceway Non Sharing",
            required: false,

            thumbnail: "/assets/components/ColorsSharing.png",

            rules: [
              {
                type: hide,
                node: ["FlipUp", "gorment", "Raceway"],
              },
            ],
            options: [
              // {
              //   name: "None",
              //   thumbnail: "/assets/components/none.jpg",
              //   // options: [...boxraceway],
              //   // enableFeatures: ["Main Modesty"],
              //   rules: [
              //     {
              //       type: hide,
              //       node: [
              //         "Flip Up",
              //         "Raceway",
              //         "gorment2",
              //         "gorment2@work",
              //         "gorment@work",
              //         "FlipUp@work",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["Table_top"],
              //     },
              //   ],
              // },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],
                enableFeatures: ["Wire Management's Dimensions Non Sharing"],

                rules: [
                  {
                    type: show,

                    node: ["FlipUp", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,

                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment@work",
                      "gorment2@work",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Eco", "Noah", "Scope", "IO"],
                      },
                    ],
                    node: [
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "gorment@work",
                      "gorment2@work",
                    ],
                  },

                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["FlipUp", "gorment2@work", "connecter"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["FlipUp@work"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                disableOptions: ["Mid Prelam", "Mid Metal"],
                disableFeatures: ["Wire Management's Dimensions Non Sharing"],
                rules: [
                  {
                    type: hide,
                    node: ["FlipUp", "Raceway", "gorment2", "FlipUp@work"],
                  },
                  {
                    type: show,
                    node: [
                      "gorment",
                      "Table_top",
                      "connecter",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "mid_leg",
                      "prelammid",
                      "prelammid@work",
                      "metalmodestymid",
                      "metalmodestymid@work",
                    ],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment", "gorment2@work"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment@work"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                disableFeatures: ["Wire Management's Dimensions Non Sharing"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipUp",
                      "Raceway",
                      "gorment",
                      "gorment2",
                      "FlipUp@work",
                      "gorment@work",
                      "mid_leg_gorment",
                    ],
                  },
                  {
                    type: show,
                    node: ["gorment2", "connecter", "Table_top"],
                  },
                  {
                    type: show,
                    node: ["mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: [
                      "gorment2",
                      "Flipup@work",
                      "gorment@work",
                      "connecter",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["gorment2@work"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Management's Dimensions Non Sharing",
            multiSelect: true,
            options: [
              {
                name: "300x145",
                required: false,
              },
              // {
              //   name: " 400 x 145mm ",
              //   required: false,
              // },
              {
                name: "450x145",
                required: false,
              },
              {
                name: "500x145",
                required: false,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy",
    multiSelect: false,
    disableFeatures: [
      "Aluminium Panel Options (S)",
      "Fabric Panel Accessories (S)",
    ],
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        disableFeatures: [
          "Aluminium Panel Options (S)",
          "Fabric Panel Accessories (S)",
          "End to End Panel Options (S)",
        ],
        options: [
          {
            name: "Side Modesty (S)",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",

                    node: ["prelamleft", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",

                    node: ["metalmodestyleft", "metalmodestyright"],
                  },

                  {
                    type: "hide",
                    node: ["prelamleft", "prelamright"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty (S)",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels (S)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                  "Privacy Panel Height(S)",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: ["300G", "300A", "450G", "450A"],
                enableOptions: ["300F", "450F"],
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "Privacy Panel Height(S)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: ["300F", "300A", "450F", "450A"],
                enableOptions: ["300G", "450G"],
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: ["Privacy Panel Height(S)"],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                disableOptions: ["300F", "300G", "450F", "450G"],
                enableOptions: ["300A", "450A"],
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Privacy Panel Height(S)"],
                disableFeatures: [
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                ],
              },
              // {
              //   name: "end to end panels",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["End to End Panel Options (S)"],
              //   disableFeatures: [
              //     "Fabric Panel Accessories (S)",
              //     "Aluminium Panel Options (S)",
              //   ],
              //   rules: [
              //     {
              //       type: replacePart,
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/68f3c295-7cee-4c0a-93ca-bd71fc652169/4_5_2024/1717499694262/end-to-end-panels-.glb",
              //     },
              //     {
              //       type: replacePart,
              //       condition: [
              //         {
              //           feature: "SHARING",
              //           values: ["Two Seater"],
              //         },
              //       ],
              //       node: [
              //         "End1",
              //         "End2",
              //         "End3",
              //         "End4",
              //         "End5",
              //         "End6",
              //         "End7",
              //         "End8",
              //         "End9",
              //         "End10",
              //         "End11",
              //         "End12",
              //       ],
              //       pid: "https://content.helloviewer.io/uploads/13e0c646-8b9c-4974-82a8-2f19c1fce3fc/24_5_2024/1719233596450/2seaterendtoendpane-.glb",
              //     },
              //     {
              //       type: hide,
              //       node: [
              //         "aluminium",
              //         "alumfullfabric",
              //         "fabric",
              //         "alumfullfabric",
              //         "glass",
              //         "alumglass",
              //         "fabric",
              //         "aluminium",
              //         "alumfullfabric",
              //         "alumfulllaminate",
              //         "alumhalfandhalf",
              //         "alumglass",
              //         "endfulllaminate008",
              //         "endglass008",
              //         "endhalfandhalf008",
              //         "endfulllaminate011",
              //         "endglassendhalfandhalf011",
              //         "endglass011",
              //         "endhalfandhalf011",
              //       ],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Privacy Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options (S)"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options (S)"],
                required: false,
              },
            ],
          },
          {
            name: "Return Panels (S)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                enableFeatures: ["Side Modesty (S)"],
                disableFeatures: ["Return Panel Height(S)"],
                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RG", "450RG", "300RH", "450RH"],
                enableOptions: ["300RF", "450RF"],

                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],

                    pid: "https://content.helloviewer.io/uploads/59b08091-72f7-40a7-afc9-6dc07897f20f/4_5_2024/1717493219410/returnfabricscreen.glb",
                  },
                  // {
                  //   type: "hide",
                  //   node: [
                  //     "glass",
                  //     "aluminium",
                  //     "alumfabric",
                  //     "alumfullfabric",
                  //     "alumfulllaminate",
                  //     "alumhalfandhalf",
                  //     "hangingwhiteboard",
                  //     "metal_nameplate",
                  //     "Alluminiumname_board",
                  //     "rail",
                  //   ],
                  // },
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                ],
                enableFeatures: ["Side Modesty (S)", "Return Panel Height(S)"],
                disableOptions: ["300RF", "450RF", "300RH", "450RH"],
                enableOptions: ["300RG", "450RG"],
                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],

                    pid: "https://content.helloviewer.io/uploads/41dbd369-2caa-41df-a3bd-3d18a0e7dffc/5_5_2024/1717567546607/glass-panels-mid-.glb",
                  },
                ],
              },
              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Aluminium Panel Options (S)",
                  "Fabric Panel Accessories (S)",
                  "End to End Panel Options (S)",
                  "Side Modesty (S)",
                ],
                enableFeatures: ["Return Panel Height(S)"],
                disableOptions: ["300RF", "450RF", "300RG", "450RG"],
                enableOptions: ["300RH", "450RH"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanel"],
                  },
                ],
              },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options (S)"],
              //   disableFeatures: ["Fabric Panel Accessories (S)"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Return Panel Height(S)",
            multiSelect: true,
            options: [
              {
                name: "300RF",
                required: false,
              },

              {
                name: "450RF",
                required: false,
              },
              {
                name: "300RG",
                required: false,
              },

              {
                name: "450RG",
                required: false,
              },
              {
                name: "300RH",
                required: false,
              },

              {
                name: "450RH",
                required: false,
              },
            ],
          },
          {
            name: "Aluminium Panel Options (S)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "End to End Panel Options (S)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  // {
                  //   type: "show",
                  //   node: [
                  //     "endfullfabric",
                  //     "endfullfabric001",
                  //     "endfullfabric002",
                  //     "endfullfabric003",
                  //     "endfullfabric004",
                  //     "endfullfabric005",
                  //     "endfullfabric006",
                  //     "endfullfabric007",
                  //     "endfullfabric008",
                  //     "endfullfabric009",
                  //     "endfullfabric010",
                  //     "endfullfabric011",
                  //   ],
                  // },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "aluminium",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                    ],
                  },
                ],
              },
              {
                name: "Full Pin up",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  // {
                  //   type: "show",
                  //   node: [
                  //     "endfullfabric",
                  //     "endfullfabric001",
                  //     "endfullfabric002",
                  //     "endfullfabric003",
                  //     "endfullfabric004",
                  //     "endfullfabric005",
                  //     "endfullfabric006",
                  //     "endfullfabric007",
                  //     "endfullfabric008",
                  //     "endfullfabric009",
                  //     "endfullfabric010",
                  //     "endfullfabric011",
                  //   ],
                  // },
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "aluminium",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                    ],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: [
                      "endhalfandhalf",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "aluminium",
                      "alumfullfabric",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                      "endfullfabric",
                      "endfullfabric001",
                      "endfullfabric002",
                      "endfullfabric003",
                      "endfullfabric004",
                      "endfullfabric005",
                      "endfullfabric006",
                      "endfullfabric007",
                      "endfullfabric008",
                      "endfullfabric009",
                      "endfullfabric010",
                      "endfullfabric011",
                      "endfullfabric",
                      "endfullfabric001",
                      "endfullfabric002",
                      "endfullfabric003",
                      "endfullfabric004",
                      "endfullfabric005",
                      "endfullfabric006",
                      "endfullfabric007",
                      "endfullfabric008",
                      "endfullfabric009",
                      "endfullfabric010",
                      "endfullfabric011",
                    ],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    node: [
                      "endhalfandhalf",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "aluminium",
                      "alumfullfabric",
                      "alumglass",
                      "endfulllaminate",
                      "endglass",
                      "endhalfandhalf",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                      "endfullfabric",
                      "endfullfabric001",
                      "endfullfabric002",
                      "endfullfabric003",
                      "endfullfabric004",
                      "endfullfabric005",
                      "endfullfabric006",
                      "endfullfabric007",
                      "endfullfabric008",
                      "endfullfabric009",
                      "endfullfabric010",
                      "endfullfabric011",
                    ],
                  },
                ],
              },
              {
                name: "Full laminate",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    node: [
                      "endfulllaminate",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                    ],
                  },
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumglass",
                      "alumfulllaminate",
                      "aluminium",
                      "endglass",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                      "endfullfabric",
                      "endfullfabric001",
                      "endfullfabric002",
                      "endfullfabric003",
                      "endfullfabric004",
                      "endfullfabric005",
                      "endfullfabric006",
                      "endfullfabric007",
                      "endfullfabric008",
                      "endfullfabric009",
                      "endfullfabric010",
                      "endfullfabric011",
                      "endhalfandhalf",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium Glass",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    node: [
                      "endglass",
                      "endglass001",
                      "endglass002",
                      "endglass003",
                      "endglass004",
                      "endglass005",
                      "endglass006",
                      "endglass007",
                      "endglass008",
                      "endglass009",
                      "endglass010",
                      "endglass011",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "alumglass",
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "endfullfabric",
                      "endfullfabric001",
                      "endfullfabric002",
                      "endfullfabric003",
                      "endfullfabric004",
                      "endfullfabric005",
                      "endfullfabric006",
                      "endfullfabric007",
                      "endfullfabric008",
                      "endfullfabric009",
                      "endfullfabric010",
                      "endfullfabric011",
                      "endhalfandhalf",
                      "endhalfandhalf001",
                      "endhalfandhalf002",
                      "endhalfandhalf003",
                      "endhalfandhalf004",
                      "endhalfandhalf005",
                      "endhalfandhalf006",
                      "endhalfandhalf007",
                      "endhalfandhalf008",
                      "endhalfandhalf009",
                      "endhalfandhalf010",
                      "endhalfandhalf011",
                      "endfulllaminate",
                      "endfulllaminate001",
                      "endfulllaminate002",
                      "endfulllaminate003",
                      "endfulllaminate004",
                      "endfulllaminate005",
                      "endfulllaminate006",
                      "endfulllaminate007",
                      "endfulllaminate008",
                      "endfulllaminate009",
                      "endfulllaminate010",
                      "endfulllaminate011",
                    ],
                  },
                ],
              },
            ],
          },

          {
            name: "Fabric Panel Accessories (S)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Aluminium name board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (S)", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Side Modesty (NS)",
            nodeName: [
              "prelamleft",
              "metalmodestyleft",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelamleft",
                      "prelamright",
                      "metalmodestyleft",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",

                    node: ["prelamleft", "prelamright"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",

                    node: ["metalmodestyleft", "metalmodestyright"],
                  },
                  {
                    type: "hide",

                    node: ["prelamleft", "prelamright"],
                  },
                ],
              },
            ],
          },
          {
            name: "Mid Modesty (NS)",
            nodeName: [
              "prelammid",
              "metalmodestymid",
              "metalmodestymid@work",
              "prelammid@work",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                    ],
                  },
                ],
              },
              {
                name: "Mid Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "show",
                    node: ["prelammid"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodestymid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid", "metalmodestymid@work"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: ["metalmodestymid"],
                  },
                  {
                    type: "hide",
                    node: ["prelammid", "gorment"],
                  },
                  {
                    type: "hide",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["prelammid@work", "metalmodestymid"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["@work"],
                      },
                    ],
                    node: ["metalmodestymid@work"],
                  },
                ],
              },
            ],
          },

          {
            name: "Privacy Panels (NS)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                enableFeatures: ["Side Modesty (NS)"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                  "Privacy Panel Height(NS)",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "Alluminiumname_board",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "aluminium",
                      "alumfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanelmain",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",

                disableOptions: [
                  "300G",
                  "450G",
                  "300A",
                  "450A",
                  "300H",
                  "450H",
                ],
                enableOptions: ["300F", "450F"],
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "End to End Panel Options (NS)",
                ],
                enableFeatures: [
                  "Fabric Panel Accessories (NS)",
                  "Side Modesty (NS)",
                  "Privacy Panel Height(NS)",
                ],

                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "glass",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanelmain",
                    ],
                  },
                ],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Privacy Panel Height(NS)",
                ],
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                ],
                disableOptions: [
                  "300F",
                  "450F",
                  "300A",
                  "450A",
                  "300H",
                  "450H",
                ],
                enableOptions: ["300G", "450G"],
                rules: [
                  {
                    type: "show",
                    node: ["glass"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                      "hungpanelmain",
                    ],
                  },
                ],
              },
              {
                name: "Aluminium",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Privacy Panel Height(NS)",
                ],
                disableFeatures: [
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                ],
                disableOptions: [
                  "300G",
                  "450G",
                  "300F",
                  "450F",
                  "300H",
                  "450H",
                ],
                enableOptions: ["300A", "450A"],
                rules: [
                  {
                    type: "show",
                    node: ["aluminium", "alumfullfabric"],
                  },
                  {
                    type: "hide",
                    node: [
                      "fabric",
                      "glass",
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "hungpanelmain",
                      "End12",
                    ],
                  },
                ],
              },

              {
                name: "Hung Panel Main",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                enableFeatures: ["Side Modesty (NS)"],
                disableOptions: [
                  "300G",
                  "450G",
                  "300A",
                  "450A",
                  "300F",
                  "450F",
                ],
                enableOptions: ["300H", "450H"],
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Aluminium Panel Options (NS)",
                  "Fabric Panel Accessories (NS)",
                  "End to End Panel Options (NS)",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "prelammid",
                      "metalmodestymid",
                      "metalmodestymid@work",
                      "prelammid@work",
                      "aluminium",
                      "alumfullfabric",
                      "fabric",
                      "alumfullfabric",
                      "glass",
                      "alumglass",
                      "fabric",
                      "aluminium",
                      "alumfullfabric",
                      "alumfulllaminate",
                      "alumhalfandhalf",
                      "alumglass",
                      "endfulllaminate008",
                      "endglass008",
                      "endhalfandhalf008",
                      "endfulllaminate011",
                      "endglassendhalfandhalf011",
                      "endglass011",
                      "endhalfandhalf011",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "End1",
                      "End2",
                      "End3",
                      "End4",
                      "End5",
                      "End6",
                      "End7",
                      "End8",
                      "End9",
                      "End10",
                      "End11",
                      "End12",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanelmain"],
                  },
                ],
              },
            ],
          },
          {
            name: "Privacy Panel Height(NS)",
            multiSelect: true,
            options: [
              {
                name: "300F",
                required: false,
              },

              {
                name: "450F",
                required: false,
              },
              {
                name: "300G",
                required: false,
              },

              {
                name: "450G",
                required: false,
              },
              {
                name: "300A",
                enableFeatures: ["Aluminium Panel Options (NS)"],
                enableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                disableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                required: false,
              },

              {
                name: "450A",
                disableOptions: [
                  "Aluminium Glass3",
                  "Full laminate3",
                  "Half Fabric half laminate3",
                  "Half Pin up half laminate3",
                  "Full Pin up3",
                  "Full Fabric3",
                ],
                enableOptions: [
                  "Aluminium Glass4",
                  "Full laminate4",
                  "Half Fabric half laminate4",
                  "Half Pin up half laminate4",
                  "Full Pin up4",
                  "Full Fabric4",
                ],
                enableFeatures: ["Aluminium Panel Options (NS)"],
                required: false,
              },
              {
                name: "300H",
                required: false,
              },

              {
                name: "450H",
                required: false,
              },
            ],
          },
          {
            name: "Return Panels (NS)",
            nodeName: ["fabric", "glass", "aluminium"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                disableFeatures: [
                  "End to End Panel Options (NS)",
                  "Return Panel Height(NS)",
                ],
                enableFeatures: ["Side Modesty (NS)"],
                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: [
                  "300RG",
                  "450RG",
                  "300RA",
                  "450RA",
                  "300RH",
                  "450RH",
                ],
                enableOptions: ["300RF", "450RF"],

                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],

                    pid: "https://content.helloviewer.io/uploads/a30dc460-462f-4ada-8936-9d749ff20fcb/5_5_2024/1717535541370/midfabric.glb",
                  },
                  // {
                  //   type: "hide",
                  //   node: [
                  //     "glass",
                  //     "aluminium",
                  //     "alumfabric",
                  //     "alumfullfabric",
                  //     "alumfulllaminate",
                  //     "alumhalfandhalf",
                  //     "hangingwhiteboard",
                  //     "metal_nameplate",
                  //     "Alluminiumname_board",
                  //     "rail",
                  //   ],
                  // },
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
              },
              {
                name: "Glass",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [],
                enableFeatures: [
                  "Side Modesty (NS)",
                  "Return Panel Height(NS)",
                ],
                disableOptions: [
                  "300RF",
                  "450RF",
                  "300RA",
                  "450RA",
                  "300RH",
                  "450RH",
                ],
                enableOptions: ["300RG", "450RG"],
                rules: [
                  {
                    type: hide,
                    node: ["hungpanel"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],

                    pid: "https://content.helloviewer.io/uploads/5f50051b-8bf3-467a-9f1d-1e027143cd83/5_5_2024/1717535616785/midglass.glb",
                  },
                ],
              },
              {
                name: "Hung Panel",
                thumbnail: "assets/system/thumbnails/Parts/glass panel.png",
                disableOptions: [
                  "300RG",
                  "450RG",
                  "300RA",
                  "450RA",
                  "300RF",
                  "450RF",
                ],
                enableOptions: ["300RH", "450RH"],
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Side Modesty (NS)"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "prelamleft",
                      "metalmodestyleft",
                      "prelamright",
                      "metalmodestyright",
                      "mid1",
                      "mid2",
                      "mid3",
                      "mid4",
                      "mid5",
                      "mid6",
                      "mid7",
                      "mid8",
                      "mid9",
                      "mid10",
                      "mid11",
                      "mid12",
                      "mid13",
                    ],
                  },
                  {
                    type: show,
                    node: ["hungpanel"],
                  },
                ],
              },
              // {
              //   name: "Aluminium",
              //   thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableFeatures: ["Aluminium Panel Options (NS)"],
              //   disableFeatures: ["Fabric Panel Accessories (NS)"],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["aluminium", "alumfullfabric"],
              //     },
              //     {
              //       type: "hide",
              //       node: ["fabric", "glass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Return Panel Height(NS)",
            multiSelect: true,
            options: [
              {
                name: "300RF",
                required: false,
              },

              {
                name: "450RF",
                required: false,
              },
              {
                name: "300RG",
                required: false,
              },

              {
                name: "450RG",
                required: false,
              },
              {
                name: "300RA",
                required: false,
              },

              {
                name: "450RA",
                required: false,
              },
              {
                name: "300RH",
                required: false,
              },

              {
                name: "450RH",
                required: false,
              },
            ],
          },
          {
            name: "Aluminium Panel Options (NS)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "Full Fabric3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass3",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
              {
                name: "Full Fabric4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Full Pin up4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfullfabric", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumhalfandhalf", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Pin up half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Half Fabric half laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumhalfandhalf", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumfulllaminate", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Full laminate4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumfulllaminate", "aluminium"],
                  },
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric", "alumglass"],
                  },
                ],
              },
              {
                name: "Aluminium Glass4",
                thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: show,
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Aluminium"] },
                    ],
                    node: ["alumglass"],
                  },
                  {
                    type: hide,
                    node: [
                      "alumhalfandhalf",
                      "alumfullfabric",
                      "alumfulllaminate",
                    ],
                  },
                ],
              },
            ],
          },
          // {
          //   name: "End to End Panel Options (NS)",
          //   nodeName: ["aluminium", "alumfabric"],
          //   required: false,
          //   options: [
          //     {
          //       name: "Full Fabric",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       disableOptions: [""],
          //       // disableFeatures: ["Color Options Privacy"],
          //       // enableOptions: ['ColorOptionsPrivacy'],
          //       rules: [
          //         // {
          //         //   type: "show",
          //         //   node: [
          //         //     "endfullfabric",
          //         //     "endfullfabric001",
          //         //     "endfullfabric002",
          //         //     "endfullfabric003",
          //         //     "endfullfabric004",
          //         //     "endfullfabric005",
          //         //     "endfullfabric006",
          //         //     "endfullfabric007",
          //         //     "endfullfabric008",
          //         //     "endfullfabric009",
          //         //     "endfullfabric010",
          //         //     "endfullfabric011",
          //         //   ],
          //         // },
          //         {
          //           type: "hide",
          //           node: [
          //             "alumfullfabric",
          //             "aluminium",
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "alumglass",
          //             "endfulllaminate",
          //             "endglass",
          //             "endhalfandhalf",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Full Pin up",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // disableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         // {
          //         //   type: "show",
          //         //   node: [
          //         //     "endfullfabric",
          //         //     "endfullfabric001",
          //         //     "endfullfabric002",
          //         //     "endfullfabric003",
          //         //     "endfullfabric004",
          //         //     "endfullfabric005",
          //         //     "endfullfabric006",
          //         //     "endfullfabric007",
          //         //     "endfullfabric008",
          //         //     "endfullfabric009",
          //         //     "endfullfabric010",
          //         //     "endfullfabric011",
          //         //   ],
          //         // },
          //         {
          //           type: "hide",
          //           node: [
          //             "alumfullfabric",
          //             "aluminium",
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "alumglass",
          //             "endfulllaminate",
          //             "endglass",
          //             "endhalfandhalf",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Half Pin up half laminate",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // enableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "endhalfandhalf",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //           ],
          //         },
          //         {
          //           type: "hide",
          //           node: [
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "aluminium",
          //             "alumfullfabric",
          //             "alumglass",
          //             "endfulllaminate",
          //             "endglass",
          //             "endhalfandhalf",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //             "endfullfabric",
          //             "endfullfabric001",
          //             "endfullfabric002",
          //             "endfullfabric003",
          //             "endfullfabric004",
          //             "endfullfabric005",
          //             "endfullfabric006",
          //             "endfullfabric007",
          //             "endfullfabric008",
          //             "endfullfabric009",
          //             "endfullfabric010",
          //             "endfullfabric011",
          //             "endfullfabric",
          //             "endfullfabric001",
          //             "endfullfabric002",
          //             "endfullfabric003",
          //             "endfullfabric004",
          //             "endfullfabric005",
          //             "endfullfabric006",
          //             "endfullfabric007",
          //             "endfullfabric008",
          //             "endfullfabric009",
          //             "endfullfabric010",
          //             "endfullfabric011",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Half Fabric half laminate",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // enableFeatures: ["Color Options Privacy"],

          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "endhalfandhalf",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //           ],
          //         },
          //         {
          //           type: "hide",
          //           node: [
          //             "alumfulllaminate",
          //             "alumhalfandhalf",
          //             "aluminium",
          //             "alumfullfabric",
          //             "alumglass",
          //             "endfulllaminate",
          //             "endglass",
          //             "endhalfandhalf",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //             "endfullfabric",
          //             "endfullfabric001",
          //             "endfullfabric002",
          //             "endfullfabric003",
          //             "endfullfabric004",
          //             "endfullfabric005",
          //             "endfullfabric006",
          //             "endfullfabric007",
          //             "endfullfabric008",
          //             "endfullfabric009",
          //             "endfullfabric010",
          //             "endfullfabric011",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Full laminate",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // enableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "endfulllaminate",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //           ],
          //         },
          //         {
          //           type: "hide",
          //           node: [
          //             "alumhalfandhalf",
          //             "alumfullfabric",
          //             "alumglass",
          //             "alumfulllaminate",
          //             "aluminium",
          //             "endglass",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //             "endfullfabric",
          //             "endfullfabric001",
          //             "endfullfabric002",
          //             "endfullfabric003",
          //             "endfullfabric004",
          //             "endfullfabric005",
          //             "endfullfabric006",
          //             "endfullfabric007",
          //             "endfullfabric008",
          //             "endfullfabric009",
          //             "endfullfabric010",
          //             "endfullfabric011",
          //             "endhalfandhalf",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //           ],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Aluminium Glass",
          //       thumbnail: "assets/system/thumbnails/Parts/alumpanel.png",
          //       //pid: "6122f35afe408b1279847325",
          //       // disableFeatures: ["Color Options Privacy"],
          //       rules: [
          //         {
          //           type: show,
          //           node: [
          //             "endglass",
          //             "endglass001",
          //             "endglass002",
          //             "endglass003",
          //             "endglass004",
          //             "endglass005",
          //             "endglass006",
          //             "endglass007",
          //             "endglass008",
          //             "endglass009",
          //             "endglass010",
          //             "endglass011",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           node: [
          //             "alumglass",
          //             "alumhalfandhalf",
          //             "alumfullfabric",
          //             "alumfulllaminate",
          //             "endfullfabric",
          //             "endfullfabric001",
          //             "endfullfabric002",
          //             "endfullfabric003",
          //             "endfullfabric004",
          //             "endfullfabric005",
          //             "endfullfabric006",
          //             "endfullfabric007",
          //             "endfullfabric008",
          //             "endfullfabric009",
          //             "endfullfabric010",
          //             "endfullfabric011",
          //             "endhalfandhalf",
          //             "endhalfandhalf001",
          //             "endhalfandhalf002",
          //             "endhalfandhalf003",
          //             "endhalfandhalf004",
          //             "endhalfandhalf005",
          //             "endhalfandhalf006",
          //             "endhalfandhalf007",
          //             "endhalfandhalf008",
          //             "endhalfandhalf009",
          //             "endhalfandhalf010",
          //             "endhalfandhalf011",
          //             "endfulllaminate",
          //             "endfulllaminate001",
          //             "endfulllaminate002",
          //             "endfulllaminate003",
          //             "endfulllaminate004",
          //             "endfulllaminate005",
          //             "endfulllaminate006",
          //             "endfulllaminate007",
          //             "endfulllaminate008",
          //             "endfulllaminate009",
          //             "endfulllaminate010",
          //             "endfulllaminate011",
          //           ],
          //         },
          //       ],
          //     },
          //   ],
          // },

          {
            name: "Fabric Panel Accessories (NS)",
            nodeName: ["aluminium", "alumfabric"],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",

                rules: [
                  {
                    type: "hide",
                    node: [
                      "hangingwhiteboard",
                      "metal_nameplate",
                      "Alluminiumname_board",
                      "rail",
                    ],
                  },
                ],
              },
              {
                name: "Accessories Rail",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [""],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Fabric"] },
                    ],
                    node: ["rail"],
                  },
                ],
              },
              {
                name: "Metal Nameplate",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // disableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Fabric"] },
                    ],
                    node: ["metal_nameplate"],
                  },
                  {
                    type: "hide",
                    node: ["Alluminiumname_board"],
                  },
                ],
              },
              {
                name: "Aluminium name board",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],
                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Fabric"] },
                    ],
                    node: ["Alluminiumname_board"],
                  },
                  {
                    type: "hide",
                    node: ["metal_nameplate"],
                  },
                ],
              },
              {
                name: "Hanging whiteboard",
                thumbnail: "assets/system/thumbnails/Parts/fabric panel.png",
                //pid: "6122f35afe408b1279847325",
                // enableFeatures: ["Color Options Privacy"],

                rules: [
                  {
                    type: "show",
                    condition: [
                      { feature: "Privacy Panels (NS)", values: ["Fabric"] },
                    ],
                    node: ["hangingwhiteboard"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "SKY BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SKY_BLUE.jpg",
              },
            ],
          },
          {
            name: "SOLAR YELLOW",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SOLAR_YELLOW.jpg",
              },
            ],
          },
          {
            name: "OCEAN GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/OCEAN_GREEN.jpg",
              },
            ],
          },
          {
            name: "ORANGE MIST",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/ORANGE_MIST.jpg",
              },
            ],
          },
          {
            name: "FLAME RED",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/FLAME_RED.jpg",
              },
            ],
          },
          {
            name: "CASIS PURPLE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/CASIS_PURPLE.jpg",
              },
            ],
          },
          {
            name: "AZURE BLUE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/AZURE_BLUE.jpg",
              },
            ],
          },
          {
            name: "APPLE GREEN",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/APPLE_GREEN.jpg",
              },
            ],
          },
          {
            name: "SUNNY ORANGE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUNNY_ORANGE.jpg",
              },
            ],
          },
          {
            name: "SUPER WHITE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/SUPER_WHITE.jpg",
              },
            ],
          },
          {
            name: "GREEN LIME",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "GLASS",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/GLASS/GREEN_LIME.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Tangerine",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Acid",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
              },
            ],
          },
          {
            name: "Acorn",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
              },
            ],
          },
          {
            name: "Amethyst",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
              },
            ],
          },
          {
            name: "Apollo",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
              },
            ],
          },

          {
            name: "Blush",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Burnt Orange",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Magenta",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
              },
            ],
          },

          {
            name: "Pink",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Raspberry",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
              },
            ],
          },
          {
            name: "Ruby",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
              },
            ],
          },
          {
            name: "Salmon",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
              },
            ],
          },
          {
            name: "Shimmer",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
              },
            ],
          },

          {
            name: "Electric",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
              },
            ],
          },
          {
            name: "Frengia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
              },
            ],
          },
          {
            name: "Baby Blue",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
            rules: [
              {
                type: "applyMaterial",
                node: "FABRIC",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",

    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyboardtray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "assets/system/thumbnails/Parts/keyandmousetray.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (S)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "assets/system/thumbnails/Parts/cpustand.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail:
                  "assets/system/thumbnails/Parts/hangingcpustand .png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "Keyboard Trays (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray", "Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key&mousetray"],
                  },
                  {
                    type: "show",
                    node: ["Key_board_tray"],
                  },
                ],
              },
              {
                name: "Keyboard and Mouse Tray",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["Key_board_tray"],
                  },
                  {
                    type: "show",
                    node: ["Key&mousetray"],
                  },
                ],
              },
            ],
          },
          {
            name: "CPU Stands (NS)",
            multiSelect: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand", "CPU_stand"],
                  },
                ],
              },
              {
                name: "CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["hangingcpustand"],
                  },
                  {
                    type: "show",
                    node: ["CPU_stand"],
                  },
                ],
              },
              {
                name: "Hanging CPU Stand",
                thumbnail: "/assets/components/nameboard.png",
                rules: [
                  {
                    type: "hide",
                    node: ["CPU_stand"],
                  },
                  {
                    type: "show",
                    node: ["hangingcpustand"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
