import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ConfirmAction = (
  title: string,
  message?: string,
  callback?: () => void
) => {
  return new Promise((resolve, reject) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            callback && callback();
            resolve(true);
          },
        },
        {
          label: "No",
          onClick: () => resolve(false),
        },
      ],

      overlayClassName: "overlay-custom-class-name",
      customUI: ({ onClose }) => (
        <div className="bg-white shadow-md border max-w p-4 rounded-lg ">
          <h1 className="mb-6 text-lg">{title}</h1>
          <p className="mb-3 mt-2">{message}</p>
          <div className="flex justify-center">
            <button
              className="bg-black mr-4 w-24 opacity-90 hover:opacity-100 cursor-pointer text-white px-4 py-1 rounded-md"
              onClick={() => {
                callback && callback();
                onClose();
                resolve(true);
              }}
            >
              Yes
            </button>
            <button
              className="bg-black w-24 opacity-90 hover:opacity-100 cursor-pointer text-white px-4 py-1 rounded-md"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      ),
    });
  });
};

export default ConfirmAction;
