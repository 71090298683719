import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import closeIcon from "../assets/icons/close.svg";
// import toast from "react-hot-toast";
import toast from "react-hot-toast";
import { getPricing } from "../utils/uploadPricingSheet";
import { testConfigurations } from "../const/testConfigurations";

export default function TestPricingForm({
  show,
  closeForm,
}: {
  show: boolean;
  closeForm: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState<any[]>([]);

  useEffect(() => {
    if (show) {
      const fetchPrice = async (config: any) => {
        setIsLoading(true);
        try {
          const res = await getPricing(config);
          price.push(res);
        } catch (error) {
          toast.error("Unable to get pricing");
        }
        setIsLoading(false);
      };
      const fetchAllPrices = async () => {
        for (let i = 0; i < testConfigurations.length; i++) {
          await fetchPrice(testConfigurations[i].config);
        }
      };
      fetchAllPrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div>
      <Modal show={show} onClose={() => null} type="pricing">
        <form
          //   onSubmit={}
          className="p-2 w-96 flex flex-col overflow-y-scroll"
          style={{ maxHeight: "85vh", width: "60vw", maxWidth: "700px" }}
        >
          <div className="flex mb-4">
            {/* <h1 className="font-bold text-lg ">Pricing</h1> */}

            <img
              className="ml-auto cursor-pointer"
              onClick={() => {
                if (!isLoading) {
                  closeForm();
                  setPrice([]);
                }
              }}
              src={closeIcon}
              alt="close"
            />
          </div>
          <div className="flex font-bold justify-between border-b mb-2">
            <p className="mb-2 text-lg ml-5">Configuration</p>
            <p className="mb-2 text-lg mr-5">Price </p>
          </div>
          {price?.length >= testConfigurations.length ? (
            testConfigurations.map((config, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <p className="mb-2 text-lg">{config.name} </p>
                  <p className="mb-2 text-lg">INR {price[index]} </p>
                </div>
              );
            })
          ) : (
            <p className="font-bold text-lg text-center">Loading...</p>
          )}
        </form>
      </Modal>
    </div>
  );
}
