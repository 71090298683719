import { tableInfoConstants } from "../actions/types";
const initialState: {
  info: any;
  type: string;
} = {
  info: undefined,
  type: "",
};

export const tableInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case tableInfoConstants.SET_TABLE_INFO:
      return {
        ...state,
        info: action.payload,
      };

    case tableInfoConstants.SET_TABLE_TYPE:
      return {
        ...state,
        type: action.payload,
      };

    default:
      return state;
  }
};
