import { Order } from "../../types/types";
import { ordersConstants } from "../actions/types";

type Action = { type: any; payload: any };

type InitialState = {
  orders: Order[];
  searchedOrders: Order[];
  currentOrder: Order | null;
  loadingOrders: boolean;
  totalOrders: number;
  currentPage: number;
  pageSize: number;
};

const initialState: InitialState = {
  orders: [],
  searchedOrders: [],
  currentOrder: null,
  loadingOrders: false,
  totalOrders: 0,
  currentPage: 1,
  pageSize: 10,
};

export const ordersReducer = (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ordersConstants.SET_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case ordersConstants.SET_SEARCHED_ORDERS:
      return {
        ...state,
        searchedOrders: payload,
      };
    case ordersConstants.SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: payload,
      };
    case ordersConstants.ADD_ORDER:
      return {
        ...state,
        orders: [payload, ...state.orders],
      };
    case ordersConstants.UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order._id === payload._id ? payload : order
        ),
      };
    case ordersConstants.DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order._id !== payload._id),
      };
    case ordersConstants.SET_LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: payload,
      };
    case ordersConstants.SET_TOTAL_ORDERS:
      return {
        ...state,
        totalOrders: payload,
      };
    case ordersConstants.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case ordersConstants.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      };
    default:
      return state;
  }
};
