import { configurationConstants } from "../store/actions/types";
import { useAppDispatch, useAppSelector } from "./hooks";

export default function useConfiguration(): {
  userConfiguration: any;
  updateConfiguration: (key: string, obj: any) => void;
} {
  const dispatch = useAppDispatch();

  const userConfiguration = useAppSelector(
    (state) => state.configuration.userConfiguration
  );
  const primaryBarSelection = useAppSelector(
    (state) => state.configuration.primaryBarSelection
  );

  const updateConfiguration = (key: string, obj: any) => {
    let newUserConfiguration: any = {
      ...userConfiguration,
      [key]: obj,
    };
    if (key === "Privacy") {
      delete newUserConfiguration.Accessories;
    }
    // newUserConfiguration = removeSucceedingEntries(key, newUserConfiguration);

    dispatch({
      type: configurationConstants.SET_CONFIGURATION,
      payload: newUserConfiguration,
    });

    // Updates selectionStatus

    if (!primaryBarSelection) {
      return;
    }

    if (
      !primaryBarSelection?.multiSelect ||
      (newUserConfiguration[primaryBarSelection.name] &&
        newUserConfiguration[primaryBarSelection.name].length ===
          primaryBarSelection.options[0].options.length)
    ) {
      dispatch({
        type: configurationConstants.UPDATE_SELECTION_STATUS,
        payload: { [primaryBarSelection.name]: true },
      });
      //   setSelectionStatus((prevState: any) => {
      //     return { ...prevState, [primaryBarSelection.name]: true };
      //   });
    }
    // setUserConfiguration(newUserConfiguration);
  };

  return { userConfiguration, updateConfiguration };
}
