import { tableTypeConstants } from "../actions/types";
const initialState: {
  table: undefined | string;
} = {
  table: undefined,
};

export const tableTypeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case tableTypeConstants.SET_TABLE_TYPE:
      return {
        ...state,
        table: action.payload,
      };

    default:
      return state;
  }
};
